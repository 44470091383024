
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { BleedingDisorder } from "../../models/reports/Death/bleedingdisorder";
import InformationDDC from "./InformationDDC.vue";
import numberRound from "../../mixins/roundValues";
import { formatFinancialAmounts, formatTwoDecimal} from '../../utils/format'

export default Vue.extend({
  name: "death",
  mixins: [numberRound],
  components: { InformationDDC },
  data: () => ({
    data: [],
    formatFinancialAmounts,
    isLoading: false,
    bleedingDisorderSelected: [],
    bleedingDisorders: [],
    payload: {
      year: null,
      bleedingDisorder: [] as number[],
    },
  }),
  computed: {
    title() {
      return i18n.t("reports.ddcreports.listReports.death");
    },
    yearSelected() {
      return this.$store.getters["ddcreport/getYearSelected"].year;
    },
  },
  methods: {
    loadBleedingDisorderType() {
      axios
        .get(`Report/DDC/Death-Breakdown/BleedingDisorderType`)
        .then((res) => {
          this.bleedingDisorders = res.data;
        })
        .catch((err) => console.log(err));
    },
    getData() {
      this.isLoading = true;
      this.payload.year = this.yearSelected;
      const arrayIDDisorders: number[] = this.bleedingDisorderSelected.map(
        function(item: BleedingDisorder) {
          return item.id;
        }
      );
      this.payload.bleedingDisorder = arrayIDDisorders;
      axios
        .post("report/DDC/Death-Breakdown", this.payload)
        .then((res: any) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      this.isLoading = true;
      this.payload.year = this.yearSelected;
      const arrayIDDisorders: number[] = this.bleedingDisorderSelected.map(
        function(item: BleedingDisorder) {
          return item.id;
        }
      );
      this.payload.bleedingDisorder = arrayIDDisorders;
      axios
        .post(`report/ddc/Death-Breakdown/export`, this.payload, {
          responseType: "blob",
        })
        .then((res) => {
          const fileName =
            `${i18n.t("reports.ddcreports.pagetitle")} -` + this.title;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    this.loadBleedingDisorderType();
  }
});
